jQuery(document).ready(function ($) {

	$('#frame-stage-tabs').addClass("active");
	$('#frame-stage').addClass("active");

	// TABS AND INDICATORS
	const tabLink = $('.tabs-nav--sub button');
	const currentMaxWidth = $('.artwork-image').css('max-width');

	function frame_navigation(activeLink) {
		let goToLink = $(activeLink.attr('data-frame-tab'));		
		let frameSlide = $('.frame-grid-slide');
		let activeStep = $(".frame-grid-controls__steps-active");
		let activeTab = $(".tabs-nav--sub__item-active");

		tabLink.removeClass('tabs-nav--sub__item-active');
		activeLink.addClass('tabs-nav--sub__item-active');
		frameSlide.removeClass('frame-grid-slide--active');
		goToLink.addClass('frame-grid-slide--active');
	}

	tabLink.on('click', function (e) {
		e.preventDefault();
		frame_navigation($(this));
	});

	function stepComplete() {
		$("input[type=radio]").change( function() {
			var currentSlide = $(this).closest(".frame-grid-slide");
			var currentSlideID = "#" + currentSlide.attr("id");
			$('[data-frame-tab="' + currentSlideID + '" ]').addClass("tabs-nav--sub__item-complete");
		});
	}

	stepComplete();

	function resetFrame() {
		let artworkShadow = $(".artwork-shadow");
		let artworkImage = $(".artwork-image");
		let artworkMount = $(".artwork-mount");
		
		$(".inc-frame").hide();
		$("#tab-framing > ul > li > button").removeClass("tabs-nav--sub__item-complete");
		$("#tab-framing > ul > li > button").removeClass("tabs-nav--sub__item-active");

		// DISABLE TABS AGAIN TO FORCE RESTART
		$("#sub-tab-1 .js-frame-grid-next").attr('disabled', true);
		$("#sub-tab-2 .js-frame-grid-next").attr('disabled', true);
		$("[data-frame-tab='#sub-tab-2']").attr('disabled', true);
		$("[data-frame-tab='#sub-tab-3']").attr('disabled', true);

		// RESET ALL SLIDES
		$('input[type="radio"]').prop('checked', false);
		$(".frame-grid").removeClass("frame-grid-slide--active");
		$("#sub-tab-1").addClass("frame-grid-slide--active");
		$(".frame-grid__item").removeClass("frame-grid__item--active");

		// VIEW IN ROOM CHANGES RESET
		artworkShadow.hide();
		artworkShadow.css({
			"top" : "0",
			"bottom" : "0",
			"left" : "0",
			"right" : "0"
		});

		artworkImage.removeClass("mount-added");
		artworkImage.removeClass("artwork-gilt");

		artworkImage.css({
			"outlineColor" : "#ffffff",
			"outlineOffset" : "0",
			"outlineWidth" : "0",
		});

		artworkMount.css("outlineWidth", "0");
	}

	function standardFrameMountChoices() {
		if ($("#standard-frames").is(':checked')) {
			$("#floating-forwards").hide();
			$("label[for=floating-forwards]").hide();
		} else {
			$("#floating-forwards").show();
			$("label[for=floating-forwards]").show();
		}
	}

	function showViewInRoom() {
		let viewInRoom = $('.view-in-room-wrapper');

		$("#tab-framing input[type=radio]").change(function() {
			if (viewInRoom.length > 0) {
				if (!viewInRoom.is(':visible')) {
					viewInRoom.css("display", "block");
					$(".flex-viewport").hide();
				}
			}

			$(".tabs-close").css("display", "inline-block");
			$("[href='#tab-framing']").addClass("portal-active");
		});
	}

	$("input[name=moulding]").change(function() {
		let artworkImage = $(".artwork-image");
		let artworkMount = $(".artwork-mount");
		let artworkShadow = $(".artwork-shadow");

		standardFrameMountChoices();
		$('[data-frame-tab="#sub-tab-2"]').attr('disabled', true);
		$('[data-frame-tab="#sub-tab-3"]').attr('disabled', true);
		$("#sub-tab-2 .js-frame-grid-next").attr('disabled', true);
		$(".js-frame-grid-submit").attr('disabled', true);
		$('[data-frame-tab="#sub-tab-2"]').removeClass("tabs-nav--sub__item-complete");
		$('[data-frame-tab="#sub-tab-3"]').removeClass("tabs-nav--sub__item-complete");
		$('input[name=colour]').prop('checked', false);
		$('input[name=mount]').prop('checked', false);

		artworkImage.removeClass("mount-added");
		artworkImage.css("outlineOffset", "0");
		artworkMount.css("outlineWidth", "0");
		artworkShadow.css({
			"top" : "0",
			"bottom" : "0",
			"left" : "0",
			"right" : "0"
		});
	});
	
	showViewInRoom();

	// NEXT BUTTON CLICK
	$(".js-frame-grid-next").on('click', function (e) {
		e.preventDefault();

		let frameSlide = $('.frame-grid-slide');
		let activeSlide = $('.frame-grid-slide--active');
		let currentIndex = parseInt(activeSlide.attr('data-slide-index'));

		let activeTab = $(".tabs-nav--sub__item-active");
		let parentActiveSlide = parseInt($(".tabs-sub-stage").attr("data-active-slide"));

		frameSlide.removeClass("frame-grid-slide--active");
		$("[data-slide-index='" + (currentIndex + 1) + "']").addClass("frame-grid-slide--active");
		frame_navigation($('[data-slide-goto="' + (currentIndex + 1) + '"]'));
		activeTab.addClass("tabs-nav--sub__item-complete");

		$(".tabs-sub-stage").attr("data-active-slide", parentActiveSlide + 1);

		$([document.documentElement, document.body]).animate({
			scrollTop: $("#frame-stage-tabs").offset().top - 200
		}, 1000);
	});

	// PREVIOUS BUTTON CLICK
	$(".js-frame-grid-prev").on('click', function (e) {
		e.preventDefault();

		let frameSlide = $('.frame-grid-slide');
		let activeSlide = $('.frame-grid-slide--active');
		let currentIndex = parseInt(activeSlide.attr('data-slide-index'));

		frameSlide.removeClass("frame-grid-slide--active");
		$("[data-slide-index='" + (currentIndex + 1) + "']").addClass("frame-grid-slide--active");
		frame_navigation($('[data-slide-goto="' + (currentIndex - 1) + '"]'));
	});


	// REMOVE DISABLED ATTRIBUTES ON TABS WHEN PROGRESS TO NEXT STAGE
	$("#sub-tab-1 .js-frame-grid-next").click( function() {
		$('[data-frame-tab="#sub-tab-2"]').removeAttr("disabled");
	});

	$("#sub-tab-2 .js-frame-grid-next").click( function() {
		$('[data-frame-tab="#sub-tab-3"]').removeAttr("disabled");
	});


	// TOOLTIP HOVERS
	$(".frame-grid__item-tooltip-click").hover(function(){
		let tooltip = $(this).closest(".frame-grid__item-tooltip");
		$(".frame-grid__item-tooltip").removeClass("tooltip-active");
		tooltip.addClass("tooltip-active");
		}, function(){
		let tooltip = $(this).closest(".frame-grid__item-tooltip");
		tooltip.removeClass("tooltip-active");
	});


	// CSS CHANGES FRAMES
	let artworkImage = $(".artwork-image");
	let artworkMount = $(".artwork-mount");
	let artworkShadow = $(".artwork-shadow");
	let mouldingItem = $(".frame-grid__item--moulding");
	let colourItem = $(".frame-grid__item--colour");
	let mountItem = $(".frame-grid__item--mount");

	mouldingItem.click( function() {
		mouldingItem.removeClass("frame-grid__item--active");
		$(this).addClass("frame-grid__item--active");

		let mouldingItemWidth = $(this).attr("data-frame-width");
		artworkImage.css("outlineWidth", mouldingItemWidth + "px");

		if (!$(this).hasClass("no-frame")) {
			artworkShadow.show();
			$(".single_add_to_cart_button").hide();
			$(".wcppec-checkout-buttons").hide();
		} else {
			artworkShadow.hide();
			$(".single_add_to_cart_button").show();
			$(".wcppec-checkout-buttons").show();
		}

	});

	colourItem.click( function() {
		colourItem.removeClass("frame-grid__item--active");
		$(this).addClass("frame-grid__item--active");

		let colourItemColour = $(this).attr("data-frame-colour");
		artworkImage.css("outlineColor", "#" + colourItemColour);

		$("#sub-tab-2 .js-frame-grid-next").removeAttr("disabled");
	});

	mountItem.click( function() {
		mountItem.removeClass("frame-grid__item--active");
		$(this).addClass("frame-grid__item--active");

		let mountItemWidth = 10;

		if ($(this).attr("for") != 'no-border') {
			artworkMount.css("outlineWidth", mountItemWidth + 'px');
			artworkImage.css("outlineOffset", mountItemWidth + 'px');
			artworkShadow.css("top", "-" + mountItemWidth + 'px');
			artworkShadow.css("bottom", "-" + mountItemWidth + 'px');
			artworkShadow.css("left", "-" + mountItemWidth + 'px');
			artworkShadow.css("right", "-" + mountItemWidth + 'px');
		} else {
			artworkMount.css("outlineWidth", 0 + 'px');
			artworkImage.css("outlineOffset", 0 + 'px');
			artworkShadow.css("top", "-" + 0 + 'px');
			artworkShadow.css("bottom", "-" + 0 + 'px');
			artworkShadow.css("left", "-" + 0 + 'px');
			artworkShadow.css("right", "-" + 0 + 'px');
		}

		$("#sub-tab-3 .js-frame-grid-submit").removeAttr("disabled");
	});

	$('input[type=radio][name=mount]').change(function() {
		if ($(this).is(':checked') && $(this).val() == 'floating-forwards') {
			$(".artwork-mount img").addClass("frame-float-forward");
			$(".artwork-mount").removeClass("frame-window-mount");
			$(".artwork-image").addClass("mount-added");

		} else if ($(this).is(':checked') && $(this).val() == 'window-mount') {
			$(".artwork-mount").addClass("frame-window-mount");
			$(".artwork-mount img").removeClass("frame-float-forward");
			$(".artwork-image").addClass("mount-added");
		} else {
			$(".artwork-mount img").removeClass("frame-float-forward");
			$(".artwork-mount").removeClass("frame-window-mount");
			$(".artwork-image").removeClass("mount-added");
		}
	});

	// UPDATE PRICE ON PRODUCT PAGE BASED ON CHOICES
	function ajaxPriceUpdates() {
		$('input[type=radio]').change(function() {

			let basePrice = parseFloat($('[itemprop="price"]').attr("content")) * 100;
			let currentPrice = $(".price .woocommerce-Price-amount").text().replace("£", "");
			let frameOptions = ['moulding', 'colour', 'mount'];
			let priceUpdate = 0;

			// CONVERT CURRENT PRICE
			currentPrice = parseFloat(currentPrice);
			currentPrice = currentPrice * 100;

			$.each( frameOptions, function( i, val ) {
				let checkedRadio = $("input[name='" + val + "']:checked");
				if (checkedRadio.length) {
					let checkedRadioPrice;
					if ($("input[name='mount']:checked").val() == 'window-mount' || $("input[name='mount']:checked").val() == 'floating-forwards') {
						checkedRadioPrice = checkedRadio.attr("data-price-inc");
					} else {
						checkedRadioPrice = checkedRadio.attr("data-price");
					}

					if (val == 'colour') {
						checkedRadioPrice = checkedRadioPrice - $("input[name='moulding']:checked").attr('data-price');
					}

					checkedRadioPrice = checkedRadioPrice * 100;
					priceUpdate = priceUpdate + checkedRadioPrice;
				}
			});

			if ( $(this).is(':checked') && $(this).val() == 'artwork-only' ) {
				priceUpdate = 0;
			}

			// CALCULATE NEW PRICE TOTAL
			let newTotal = basePrice + priceUpdate;
			newTotal = newTotal / 100;

			$.ajax({
				url: selectedframes.ajaxurl,
				type: 'POST',
				data: {
					action: 'own_art_breakdown',
					new_total: newTotal
				},
			}).done(function (data) {
				$(".ajax-breakdown").empty();
				$(".ajax-breakdown").append(data);

			});

			newTotal = "£"+((Number(newTotal)||0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"));
			$(".price .woocommerce-Price-amount").text(newTotal);
		});

	}

	ajaxPriceUpdates();


	// DISPLAY CONDITIONAL RESULTS BASED ON FRAME CHOICE
	$('input[type=radio][name=moulding]').change(function() {
		let mouldValues = $(this).val();
		let dataRebate = $(".tabs-sub-stage").attr("data-rebate");
		let ajaxResults = $(".ajax-results");
		let nextButton = $("#sub-tab-1 .js-frame-grid-next");

		// $("#hand_finish").prop("checked", false );
		if (!$(".inc-frame").length > 0 ) {
			$(".price .woocommerce-Price-amount").after('<div class="inc-frame">Including frame</div>');
		}
		$(".inc-frame").show();
	
		if ($("#standard-frames").is(':checked')) {
			$("#floating-forwards").hide();
			$("label[for=floating-forwards]").hide();
		} else {
			$("#floating-forwards").show();
			$("label[for=floating-forwards]").show();
		}

		if ($(this).is(':checked') && $(this).val() == 'artwork-only') {

			resetFrame();

			let basePrice = parseFloat($('[itemprop="price"]').attr("content")) * 100;
			newTotal = "£"+((Number(basePrice)||0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"));
			$(".price .woocommerce-Price-amount").text(newTotal);


		} else {
			$.ajax({
				url: selectedframes.ajaxurl,
				type: 'POST',
				data: {
					action: 'selected_frames',
					mouldValues: mouldValues,
					dataRebate: dataRebate,
				},
				beforeSend: function (xhr) {
					let buttonWidth = nextButton.outerWidth();
					nextButton.css("width", buttonWidth + 'px');
					nextButton.text('');
					nextButton.addClass('loading');
					ajaxResults.addClass('loading');
				},
			})
			.done(function (data) {
				nextButton.text('Next');
				nextButton.removeClass('loading');
				ajaxResults.empty();
				ajaxResults.append(data);
				ajaxResults.removeClass('loading');
				nextButton.removeAttr("disabled");
				$("[data-frame-tab='#sub-tab-2']").attr('disabled', false);

				standardFrameMountChoices();
				showViewInRoom();
				stepComplete();

				if($('#sub-tab-2').hasClass('random')) {
					shuffleColours();
				}


	
				let colourItem = $(".frame-grid__item--colour");
				let artworkImage = $(".artwork-image");
	
				colourItem.click( function() {
					colourItem.removeClass("frame-grid__item--active");
					$(this).addClass("frame-grid__item--active");
			
					let colourItemColour = $(this).attr("data-frame-colour");
					artworkImage.css("outlineColor", "#" + colourItemColour);
			
					$("#sub-tab-2 .js-frame-grid-next").removeAttr("disabled");
				});


				$('input[type=radio][name=colour]').change(function() {

					if ($(this).is(':checked') && $(this).val() == '049C' || $(this).is(':checked') && $(this).val() == '169S') {
						$(".artwork-image").addClass("artwork-gilt");
					} else {
						$(".artwork-image").removeClass("artwork-gilt");
					}

					$(".js-frame-grid-submit").attr('disabled', true);
	
					let colourSelect = $(this).val();
					let infoMsg = $(".frame-grid-info");
					let colourChoice = $(this).val();
					let framePriceOriginal = $(this).attr("data-price");
					let framePriceIncrease = $(this).attr("data-price-inc");
					let ajaxMountResults = $(".ajax-results-mount");
					let nextButtonMount = $("#sub-tab-2 .js-frame-grid-next");
					let rebateSize = $(".tabs-sub-stage").attr("data-rebate");

					$.ajax({
						url: selectedframes.ajaxurl,
						type: 'POST',
						data: {
							action: 'mount_options',
							moulding_code: colourChoice,
							frame_price_original: framePriceOriginal,
							frame_price_increase: framePriceIncrease,
							rebate_size: rebateSize,
						},
						beforeSend: function (xhr) {
							let buttonWidthMount = nextButtonMount.outerWidth();
							nextButtonMount.css("width", buttonWidthMount + 'px');
							nextButtonMount.text('');
							nextButtonMount.addClass('loading');
							ajaxMountResults.addClass('loading');
						},
					})
					.done(function (data) {
						nextButtonMount.text('Next');
						nextButtonMount.removeClass('loading');
						ajaxMountResults.empty();
						ajaxMountResults.append(data);
						ajaxMountResults.removeClass('loading');
						nextButtonMount.removeAttr("disabled");
						$("[data-frame-tab='#sub-tab-3']").attr('disabled', false);

						showViewInRoom();
						standardFrameMountChoices();
						ajaxPriceUpdates();
						stepComplete();

						// TOOLTIP HOVERS
						$(".frame-grid__item-tooltip-click").hover(function(){
							let tooltip = $(this).closest(".frame-grid__item-tooltip");
							$(".frame-grid__item-tooltip").removeClass("tooltip-active");
							tooltip.addClass("tooltip-active");
							}, function(){
							let tooltip = $(this).closest(".frame-grid__item-tooltip");
							tooltip.removeClass("tooltip-active");
						});

						let mountItem = $(".frame-grid__item--mount");
						mountItem.click( function() {
							mountItem.removeClass("frame-grid__item--active");
							$(this).addClass("frame-grid__item--active");

							let mountItemWidth = 10;
					
							if ($(this).attr("for") != 'no-border') {
								artworkMount.css("outlineWidth", mountItemWidth + 'px');
								artworkImage.css("outlineOffset", mountItemWidth + 'px');
								artworkShadow.css("top", "-" + mountItemWidth + 'px');
								artworkShadow.css("bottom", "-" + mountItemWidth + 'px');
								artworkShadow.css("left", "-" + mountItemWidth + 'px');
								artworkShadow.css("right", "-" + mountItemWidth + 'px');
							} else {
								artworkMount.css("outlineWidth", 0 + 'px');
								artworkImage.css("outlineOffset", 0 + 'px');
								artworkShadow.css("top", "-" + 0 + 'px');
								artworkShadow.css("bottom", "-" + 0 + 'px');
								artworkShadow.css("left", "-" + 0 + 'px');
								artworkShadow.css("right", "-" + 0 + 'px');
							}
					
							$("#sub-tab-3 .js-frame-grid-submit").removeAttr("disabled");
						});
					
						$('input[type=radio][name=mount]').change(function() {
							if ($(this).is(':checked') && $(this).val() == 'floating-forwards') {
								$(".artwork-mount img").addClass("frame-float-forward");
								$(".artwork-mount").removeClass("frame-window-mount");
								$(".artwork-image").addClass("mount-added");
							} else if ($(this).is(':checked') && $(this).val() == 'window-mount') {
								$(".artwork-mount").addClass("frame-window-mount");
								$(".artwork-mount img").removeClass("frame-float-forward");
								$(".artwork-image").addClass("mount-added");
							} else {
								$(".artwork-mount img").removeClass("frame-float-forward");
								$(".artwork-mount").removeClass("frame-window-mount");
								$(".artwork-image").removeClass("mount-added");
							}

							ajaxPriceUpdates();
						});
					});

				});
	
				ajaxPriceUpdates();
	
			});
		}

	});


	// ADD FRAMING CHOICE TO BASKET
	$('.js-frame-grid-submit').click(function(){
		
		// check for variation
		if($('input[name="variation_id"]').length > 0){
			var vid = $('input[name="variation_id"]').val();
		} else {
			var vid = "";
		}
		if($('input[name="product_id"]').length > 0){
			var pid = $('input[name="product_id"]').val();
		} else {
			var pid = $('*[name="add-to-cart"]').val();
		}

		var mouldingLetter = $('[name="colour"]:checked');
		var borderWidth = $('[name="mount"]:checked');
		var finalRebateSize;

		
		if ($("input[name='mount']:checked").val() == 'window-mount' || $("input[name='mount']:checked").val() == 'floating-forwards') {

			let originalRebateSize = parseFloat($('#rebate_size_hidden').val());

			if (originalRebateSize == 68) {
				finalRebateSize = originalRebateSize + 7;
			} else if (originalRebateSize == 70 || originalRebateSize == 75) {
				finalRebateSize = originalRebateSize + 5;
			} else if (originalRebateSize == 80 || originalRebateSize == 90) {
				finalRebateSize = originalRebateSize + 10;
			} else if (originalRebateSize >= 100) {
				finalRebateSize = originalRebateSize;
			} else {
				finalRebateSize = originalRebateSize + 4;
			}

		} else {
			finalRebateSize = $('#rebate_size_hidden').val();
		}

		var handFinish = mouldingLetter.attr("data-hand-finish");

		if (handFinish == '1') {
			handFinish = true;
		} else {
			handFinish = false;
		}

		var fullCode = mouldingLetter.val();
		var letterCode = fullCode.slice(fullCode.length - 1);

		if (letterCode == 'A' && finalRebateSize > 52) {
			finalRebateSize = 52;
		}

		var data = {
			action: 'nelly_framing_frontend_add',
			rebate_size: finalRebateSize,
			product_id: pid,
			variation_id: vid,
			moulding_letter: mouldingLetter.val(),
			border_width: borderWidth.attr('data-mount-measure'),
			selected_product: $('#selected_product').val(),
			artwork_title: $('#artwork_title').val(),
			artwork_edition: $('#artwork_edition').val(),
			float_forward: $('#floating-forwards').is(':checked'),
			window_mount_fof: $('#window-mount').is(':checked'),
			hand_finishing: handFinish,
			framing_portal: true
			// window_mount_fof: $('#window_mount_fof').is(':checked'),
			// edge_to_edge_bof: $('#edge_to_edge_bof').is(':checked'),
			// edge_to_edge_fof: $('#edge_to_edge_fof').is(':checked'),
			// shallow_float_shadow_gap: $('#shallow_float_shadow_gap').is(':checked'),
			// mount_colour: $('#mount_colour').val(),
			// framing_note: $('#framing_note').val(),
			// item_location: $('#item_locations').val(),
			// custom_frame: $('#custom_frame').is(':checked'),
			// fillet_colour: $('#fillet_colour').val(),
			// dry_mount: $('#dry_mount').is(':checked')
		};

		for(var prop in data) if (!data[prop]) delete data[prop];


		console.log(data);

		// $(this).addClass('processing');
		let buttonWidth = $(this).outerWidth();
		$(this).css("width", buttonWidth + 'px');
		$(this).text('');
		$(this).addClass("loading");
		
		$.post('/wp-admin/admin-ajax.php', data, function(response) {

			let activeTab = $(".tabs-nav--sub__item-active");
			let countSteps = $(".tabs-nav--sub li").length;
			let frameSlide = $('.frame-grid-slide');

			frameSlide.removeClass("frame-grid-slide--active");
			$("[data-slide-index='" + (countSteps + 1) + "']").addClass("frame-grid-slide--active");
			activeTab.addClass("tabs-nav--sub__item-complete");

			let cartCount = $(".main-navigation__counter-bubble");
			let currentCartCount = parseInt(cartCount.text());
			let updatedCartCount = currentCartCount + 2;
			
			cartCount.text(updatedCartCount);
			// controls.hide();
			$(this).hide();

			$("[data-frame-tab='#sub-tab-1']").attr('disabled', true);
			$("[data-frame-tab='#sub-tab-2']").attr('disabled', true);
			$("[data-frame-tab='#sub-tab-3']").attr('disabled', true);

			// $('#add_frame').removeClass('processing');
			// document.location.href = '/basket';
		});
	});


	// MOVING OTHER PRINTS INTO TABBED FRAME SECTION
	let groupedTitle = $('.grouped_h3');
	let frameTabs = $('#frame-stage-tabs');
	let frameStage = $("#frame-stage");
	let otherPrints = $(".grouped_form");

	frameStage.hide();

	$("#frame-stage-tabs > li").click( function() {

		let viewInRoom = $('.view-in-room-wrapper');

		if (viewInRoom.length > 0) {
			if (!viewInRoom.is(':visible')) {
				viewInRoom.css("display", "block");
				$(".flex-viewport").hide();
			}
		}

		// if ($(this).hasClass("tab-active")) {
		// 	$(this).removeClass("tab-active");
		// 	// frameStage.slideToggle();
		// 	resetFrame();
		// 	newTotal = basePrice / 100;
		// 	newTotal = "£"+((Number(newTotal)||0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"));
		// 	$(".price .woocommerce-Price-amount").text(newTotal);
		// 	$(".wcppec-checkout-buttons").show();
		// 	$(".single_add_to_cart_button").show();

		// 	$([document.documentElement, document.body]).animate({
		// 		scrollTop: 0
		// 	}, 1000);

		// } else {
		// 	$(this).addClass("tab-active");
		// 	// frameStage.slideToggle();
		// 	$(".wcppec-checkout-buttons").hide();
		// 	$(".single_add_to_cart_button").hide();
		// }
	});

	let basePrice = parseFloat($('[itemprop="price"]').attr("content")) * 100;
	let newTotal;

	$(".tabs-close").click( function() {
		resetFrame();
		newTotal = basePrice / 100;
		newTotal = "£"+((Number(newTotal)||0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"));
		$(".price .woocommerce-Price-amount").text(newTotal);
		$(".wcppec-checkout-buttons").show();
		$(".single_add_to_cart_button").show();
		$(".frame-grid-slide").removeClass("frame-grid-slide--active");
		$("#sub-tab-1").addClass("frame-grid-slide--active");
		$("#frame-stage").slideUp();
		$("#artist-stage").slideDown();
		$("[href='#tab-framing']").removeClass("portal-active");

		$(this).css("display", "none");
		
	});

	if (groupedTitle.length > 0 && frameTabs.length > 0) {
		groupedTitle.addClass("to-remove");
		otherPrints.addClass("to-remove");
		frameTabs.append('<li><a href="#tab-other-prints" rel="nofollow">Other Prints</a></li>');
		frameStage.append('<div id="tab-other-prints" class="tab"></div>');
		$("#tab-other-prints").append(otherPrints);
		$(".to-remove").hide();
		$("#tab-other-prints .to-remove").show();

		if ($('.tabs-nav')) {
			$(".tabs-nav").each(function(){

				$(this).find("a").on('click', function (event) {
					event.preventDefault();
					$(this).closest('.tabs-nav').find('a').removeClass('active');
					// $('.tabs-nav > a').removeClass('active');
					$(this).addClass('active');
		
					let parentID = $(this).closest('.tabs-nav').attr('data-stage');
					$(parentID).find('.tab').hide();
					// $('.tabs-stage > div').hide();
					$($(this).attr('href')).show();
				});

				// $(this).find("a:first").trigger('click');
			});
		}
	}

	$("#frame-stage-tabs li").click( function() {
		frameStage.show();
	});

	// $("#frame-stage-tabs li:last-child").click( function() { 
	// 	frameStage.show();
	// });

	let _artistMenu = $(".artist-menu");
	let _artistMenuTabs = $(".artist-menu li a");
	let _artistStage = $("#artist-stage");

	let _frameMenu = $("#frame-stage-tabs");
	let _frameMenuTabs = $("#frame-stage-tabs li a");
	let _frameStage = $("#frame-stage");

	_frameMenuTabs.click( function() {
		_artistStage.slideUp();
		_frameStage.slideDown();
	});

	_artistMenuTabs.click( function() {
		_artistStage.slideDown();
		_frameStage.slideUp();
	});

	function shuffleColours() {
		console.log('randomise');
		var cards = $(".frame-grid__item--colour");
		for(var i = 0; i < cards.length; i++){
			var target = Math.floor(Math.random() * cards.length -1) + 1;
			var target2 = Math.floor(Math.random() * cards.length -1) +1;
			cards.eq(target).before(cards.eq(target2));
		}
	}

});